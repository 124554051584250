/***************** Main Page ******************/
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-image: url('./assets/main-bg.svg');
  font-family: Poppins;
  font-size: 1.8rem;
  font-weight: 500;
  overflow: hidden;
}

.logo {
  margin: 5vh auto 1.5vh auto;
  height: 18vh;
}

.input-grp {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
}

.label {
  margin: 0 0 1vh 6vw;
}

.input {
  margin: 0 6vw 1vh 6vw;
  border-radius: 10px;
  border-width: 1.5px;
  height: 5vh;
  font-size: 1.2rem;
  padding-left: 2vw;
}

.error {
  margin: 0 6vw;
  padding: 0;
  font-size: 1.2rem;
  color: orange;

}

.button {
  margin: 5vh 6vw 3vh 6vw;
  height: 8vh;
  background-color: white;
  border-color: rgb(118, 118, 118);
  border-radius: 10px;
  border-width: 1.5px;
  color: red;
  font-size: inherit;
  font-weight: 750;
}



.footer {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./assets/footer-bg.svg');
}

.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 7vw;
}

.footer-text > img, .sectionha-container > img {
  height: 6vh;
}

.sectionha-container {
  display: flex;
}

.footer-image {
  height: 25vh;
  width: 25vh;
}

/***************** Registered Page ******************/

.logo-registered {
  margin: 10vh auto 0 auto;
  height: 30vh;
  width: 30vh;
}

.sectionf {
  height: 5vh;
}

.registered {
  margin: 13vh auto 0 auto;

}

.waiting-text {
  margin: 2vh auto 0 auto;
  height: 4vh;
}